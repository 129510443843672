import { useRef, useState } from "react";
import api from "../services/api";
import Papa from "papaparse";
import moment from "moment";
import loader from "../images/loader.gif";

export default function ModalImport({ close }) {
  const [month, setMonth] = useState(moment().format("M"));
  const [year, setYear] = useState(moment().format("YYYY"));
  const [currency, setCurrency] = useState();
  const [loading, setLoading] = useState();
  const inputFile = useRef(null);
  const currencies = ["USD", "AUD", "GBP", "MXN", "UAH", "EUR", "JPY", "CNY", "CAD"];

  const selectCsv = async () => {
    inputFile.current.click();
  };

  const upload = (e) => {
    e.preventDefault();

    if (!currency && e.target.files.length === 1) {
      inputFile.current.value = null;
      inputFile.current.files = null;
      return alert("Select a currency");
    }

    let files = Object.values(e.target.files);
    setLoading(files);

    inputFile.current.value = null;
    inputFile.current.files = null;

    for (let f in files) {
      let file = files[f];
      const fileReader = new FileReader();

      fileReader.onload = function (event) {
        let text = event.target.result;

        // Convert _ to .
        text = text.split("\n");
        text[0] = text[0].replace(/_/g, ".");
        text = text.join("\n");

        // Parse JSON
        let array = Papa.parse(text, {
          header: true,
          skipEmptyLines: true,
        });

        let request = api.importBudget(array.data, month, year, currency);
        request.then((res) => {
          if (files.length === 1 && f === "0") {
            if (res.status === 200) {
              close([{ result: res.data }]);
            } else {
              alert("Error: " + res.data.message);
            }
          }
          setLoading((loading) => {
            loading[f].success = res.status === 200;
            loading[f].result = res.data;
            return [...loading];
          });
        });
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <div>
      <div className="modal-header">Import CSV</div>

      {!loading ? (
        <div className="modal-body">
          <div className="label-row">
            <label> Month</label>
            <select value={month} onChange={(e) => setMonth(e.target.value)}>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>

          <div className="label-row">
            <label>Year</label>
            <select value={year} onChange={(e) => setYear(e.target.value)}>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
            </select>
          </div>

          <div className="label-row">
            <label>File currency</label>
            <select required value={currency} onChange={(e) => setCurrency(e.target.value)}>
              <option value="" disabled selected>
                Currency
              </option>
              {currencies.map((c) => (
                <option key={"curr_" + c} value={c}>
                  {c}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : (
        <div className="modal-body">
          {loading.map((file, f) => (
            <div key={f} className="label-row">
              {file.success === undefined ? (
                <div className="loader">
                  <img className="small" alt="loader" src={loader}></img>
                </div>
              ) : file.success ? (
                <div className="loader">
                  <i className="material-symbols-outlined">check_circle</i>
                </div>
              ) : (
                <div className="loader">
                  <i className="material-symbols-outlined">error</i>
                </div>
              )}
              {file.name}
            </div>
          ))}
        </div>
      )}

      <div className="modal-footer">
        <div className="btn-bar-left"></div>
        <div className="btn-bar-right">
          <button className="link" onClick={() => close()}>
            Cancel
          </button>
          {loading && loading.every((f) => f.success !== undefined) ? (
            <button className="green" onClick={() => close(loading)} style={{ marginLeft: 15 }}>
              Close
            </button>
          ) : (
            <button className="green" onClick={() => selectCsv()} disabled={loading} style={{ marginLeft: 15 }}>
              {loading ? "Loading.." : "Import CSV"}
            </button>
          )}
          <input type="file" accept=".csv" ref={inputFile} multiple={true} onChange={upload} style={{ display: "none" }} />
        </div>
      </div>
    </div>
  );
}
