export default function Modal({ close, children, size, className }) {
  return (
    <>
      <div className="modal-backdrop">
        <div className={"modal full-mobile " + size}>
          <span
            className="close-btn material-symbols-outlined"
            onClick={() => {
              close();
            }}
          >
            close
          </span>

          <div className={"modal-container " + (className ? className : "")}>{children}</div>
        </div>
      </div>
    </>
  );
}
