export function currencyFormatInt(num) {
  return (parseFloat(num) || 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function currencyFormat(num, currency, hideCurrency) {
  let result = (parseFloat(num) || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

  if (!hideCurrency) {
    if (!currency) currency = "USD";
    result += " " + currency;
  }
  return result;
}

export function currencyFormatExcel(num) {
  let result = (parseFloat(num) || 0).toFixed(2);
  return result;
}
