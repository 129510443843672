import Modal from "./Modal";

export default function ModalImportResult({ showModalResult, setShowModalResult }) {
  return (
    <Modal close={() => setShowModalResult(false)} size={"medium"}>
      <div className="modal-edit-employee">
        <div className="modal-header">Result Import</div>

        <div className="modal-body">
          <div className="alert-success">{showModalResult.success.length} Employees Imported</div>
          <h4>{showModalResult.warn.length} Warnings</h4>
          {showModalResult.warn.map((warn, i) => (
            <div key={"warn_" + i} className="alert-warn">
              {warn.message}
            </div>
          ))}
          <h4>{showModalResult.errors.length} Errors</h4>
          {showModalResult.errors.map((err, i) => (
            <div key={"err_" + i} className="alert-error">
              {err.message}
            </div>
          ))}
        </div>

        <div className="modal-footer">
          <div className="btn-bar-left"></div>
          <div className="btn-bar-right">
            <button className="green" onClick={() => setShowModalResult(false)}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
