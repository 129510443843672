import { useEffect, useState } from "react";
import api from "../services/api";
import Table from "../components/Table";
import SidebarRight from "../components/SidebarRight";
import Modal from "../components/Modal";
import EmployeEdit from "../components/EmployeEdit";
import { useAuth } from "../context/useAuth";

export default function EmployeList() {
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState({});
  const [showSidebar, setShowSidebar] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState();
  const [editItem, setEditItem] = useState();
  const [loading, setLoading] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const auth = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let data = await api.getEmployees();
      setLoading(false);
      setList(data.data);
    };

    fetchData();
  }, []);

  const config = {
    onClickRow: (item, index) => {
      setEditItem(item);
      setSelectedIndex(index);
      setShowModalEdit(true);
    },
    columns: [
      {
        key: "email",
        header: "Email",
        template: (item) => <span className="main-row">{item.email || "NO EMAIL"}</span>,
      },
      {
        header: "First Name",
        key: "firstName",
      },
      {
        header: "Last Name",
        key: "lastName",
      },
      {
        header: "HR Code",
        key: "hrCode",
      },
      {
        header: "HR Alias",
        key: "hrAlias",
      },
      {
        header: "Team",
        key: "teamName",
      },
      {
        header: "Position",
        key: "position",
      },
      {
        header: "Location",
        key: "location",
      },
      {
        header: "Division",
        key: "divisionName",
      },
      {
        header: "Currency",
        key: "currencyCode",
      },
    ],
  };

  const setFilterValue = (key) => {
    return (e) => {
      let set = {};
      set[key] = e.target.value;
      setFilter({ ...filter, ...set });
    };
  };

  const syncBos = async () => {
    let data = await api.syncBos();
    if (data.ok) {
      alert("Sync complete.\n Created: " + data.data.created + "\n Edited: " + data.data.edited);
    } else {
      alert("Sync error");
    }
  };

  return (
    <>
      <div className={"page-wrapper" + (showSidebar ? " show-sidebar-right" : "")}>
        <div className="btn-bar">
          <div className="btn-bar-left">
            {auth.user && auth.user.role === "admin" && (
              <button className="green" onClick={syncBos}>
                Sync BOS
              </button>
            )}
          </div>
          <div className="btn-bar-right">
            {!showSidebar && (
              <button onClick={() => setShowSidebar(true)}>
                <i className="material-icons">chevron_left</i>
                Filters
              </button>
            )}
          </div>
        </div>

        <div className="table-container">
          <Table config={config} filter={filter} data={list} loading={loading}></Table>
        </div>
      </div>

      <SidebarRight show={showSidebar}>
        <div className="sidebar-header">
          <div className="sidebar-title">Filters</div>
          <div className="sidebar-title-right">
            <button className="medium icon-right" onClick={() => setShowSidebar(false)}>
              Close
              <i className="material-icons">chevron_right</i>
            </button>
          </div>
        </div>
        <div className="filter-container">
          <div className="filter-row">
            <input value={filter.email} placeholder="E-mail" onChange={setFilterValue("email")} />
          </div>
          <div className="filter-row">
            <input value={filter.firstName} placeholder="First Name" onChange={setFilterValue("firstName")} />
          </div>
          <div className="filter-row">
            <input value={filter.lastName} placeholder="Last Name" onChange={setFilterValue("lastName")} />
          </div>
          <div className="filter-row">
            <input value={filter.hrCode} placeholder="HR Code" onChange={setFilterValue("hrCode")} />
          </div>
          <div className="filter-row">
            <input value={filter.hrAlias} placeholder="HR Alias" onChange={setFilterValue("hrAlias")} />
          </div>
          <div className="filter-row">
            <input value={filter.teamName} placeholder="Team" onChange={setFilterValue("teamName")} />
          </div>
          <div className="filter-row">
            <input value={filter.location} placeholder="Location" onChange={setFilterValue("location")} />
          </div>
          <div className="filter-row">
            <input value={filter.divisionName} placeholder="Division" onChange={setFilterValue("divisionName")} />
          </div>
          <div className="filter-row">
            <input value={filter.departmentName} placeholder="Department" onChange={setFilterValue("departmentName")} />
          </div>
          <div className="filter-row">
            <input value={filter.currencyCode} placeholder="Currency" onChange={setFilterValue("currencyCode")} />
          </div>
        </div>
        <div className="sidebar-footer">
          <button
            onClick={() =>
              setFilter({
                email: "",
                firstName: "",
                lastName: "",
                teamName: "",
                location: "",
                divisionName: "",
                currencyCode: "",
              })
            }
          >
            <i className="material-icons">close</i>
            Reset
          </button>
        </div>
      </SidebarRight>

      {showModalEdit && (
        <Modal close={() => setShowModalEdit(false)}>
          <EmployeEdit
            employee={editItem}
            list={list}
            setList={setList}
            index={selectedIndex}
            close={() => setShowModalEdit(false)}
          ></EmployeEdit>
        </Modal>
      )}
    </>
  );
}
