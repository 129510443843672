import { useEffect, useState } from "react";
import api from "../services/api";
import Table from "../components/Table";
import moment from "moment";
import ModalImportResult from "../components/ModalImportResult";

export default function ImportList() {
  const [list, setList] = useState([]);
  const [showModalImport, setShowModalImport] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let data = await api.getImports();
      setList(data.data);
    };

    fetchData();
  }, []);

  const config = {
    onClickRow: (item, index) => {
      setShowModalImport(item);
    },
    columns: [
      {
        key: "importDate",
        header: "Import Date",
        template: (item) => <div>{moment(item.importDate).format("Do MMM YYYY hh:mm A")}</div>,
      },
      {
        header: "Import Month",
        key: "month",
        template: (item) => (
          <div>
            {moment()
              .month(item.month - 1)
              .format("MMMM")}
          </div>
        ),
      },
      {
        header: "Import Year",
        key: "year",
        template: (item) => <div>{item.year}</div>,
      },
      {
        header: "Success",
        template: (item) => <div>{item.success?.length}</div>,
      },
      {
        header: "Errors",
        template: (item) => <div>{item.errors?.length}</div>,
      },
      {
        header: "Warn",
        template: (item) => <div>{item.warn?.length}</div>,
      },
      {
        header: "Creator",
        template: (item) => <div>{item.User?.email}</div>,
      },
    ],
  };

  return (
    <>
      <div className={"page-wrapper"}>
        <div className="btn-bar">
          <div className="btn-bar-left"></div>
          <div className="btn-bar-right"></div>
        </div>

        <div className="table-container">
          <Table defaultSort={"importDate"} config={config} data={list}></Table>
        </div>
      </div>

      {showModalImport && (
        <ModalImportResult showModalResult={showModalImport} setShowModalResult={setShowModalImport}></ModalImportResult>
      )}
    </>
  );
}
