// Context
import { currencyFormat } from "../services/utils";

export default function BudgetRow({
  field,
  type,
  title,
  employee,
  indexRow,
  visibility,
  editMode,
  highlightedRow,
  highlightedColumn,
  highlightedMonth,
  highlightCell,
  highlightedYear,
  selectable,
  currentYear,
  changeValue,
  customClass,
  editable = true,
  openModalOther,
  showUSD,
}) {
  let fieldName = field + capitalizeFirstLetter(type);
  let fieldNameCurrency = fieldName;
  if (showUSD === true) {
    fieldNameCurrency += "_USD";
  }
  if (!customClass) customClass = type + "-row";

  const getDescription = (employee) => {
    if (employee && employee.actualRole)
      return `${employee.disabled ? "Employee not in this team\n\n" : ""}Currency:\t${employee.actualRole.currencyCode || "USD"}
Department:\t${employee.actualRole.departmentName}
Division:\t${employee.actualRole.divisionName}
Location:\t${employee.actualRole.location}
Position:\t${employee.actualRole.position}
Team:\t${employee.actualRole.teamName}
TW Office:\t${employee.actualRole.twOffice}
Payroll Source:\t${employee.actualRole.payrollSource}`;
  };

  return (
    visibility[fieldName] && (
      <tr className={`${customClass} ` + (highlightedRow === employee.id + `_${type}_${field}` ? " selected" : "")}>
        {/* TITLE */}
        <td>
          <div className="data-label">
            <b>{type[0].toUpperCase()}</b>
            {title}
          </div>
        </td>
        {[...Array(13).keys()].map((m) => {
          let currency = "USD";
          let value;
          if (m === 12) {
            // Total
            value = employee.months[m][fieldName + "_USD"];
          } else {
            // Get currency value
            if (employee.months[m]) {
              currency = employee.months[m].currencyCode;
              if (showUSD === true) {
                currency = "USD";
              }
              value = employee.months[m][fieldNameCurrency];
            }
          }

          return (
            <td
              key={"row_" + m}
              className={`month_${m} buget-item-row${editable ? " editable" : ""}${highlightedColumn === m ? " selected" : ""}${
                selectable && highlightedMonth === m && highlightedYear === currentYear ? " selectedCol" : ""
              }${employee.months[m] && employee.months[m].disabled ? " disabled" : ""}`}
              onClick={() => (!editMode || !employee.months[m].disabled) && highlightCell(employee.id + `_${type}_${field}`, m)}
              title={getDescription(employee.months[m])}
            >
              {openModalOther ? (
                // OTHERS
                <>
                  <span className="cursor" onClick={() => openModalOther(indexRow, m, fieldNameCurrency)}>
                    {value !== undefined && currencyFormat(value, currency, showUSD)}
                  </span>
                </>
              ) : (
                <>
                  {editMode && m !== 12 && editable ? (
                    // EDIT
                    <input
                      className="edit-cell"
                      value={value}
                      disabled={employee.months[m] && employee.months[m].disabled}
                      onChange={(e) => changeValue(e.target.value, employee.id, indexRow, m, fieldNameCurrency)}
                    ></input>
                  ) : (
                    value !== undefined && currencyFormat(value, currency, showUSD)
                  )}
                </>
              )}
            </td>
          );
        })}
      </tr>
    )
  );
}

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}
