import React from "react";
import Routes from "./Routes";

// Context
import { ProvideAuth } from "./context/useAuth";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="main-container">
      <BrowserRouter>
        <ProvideAuth>
          <Routes />
        </ProvideAuth>
      </BrowserRouter>
    </div>
  );
}

export default App;
